import React, {FC, useEffect} from 'react';
import {
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../settingsParams';
import { classes } from './Widget.st.css';
import igulLetovaLogo from './igul-letova-logo.png';
import closeButtoImg from './close-icon.svg';
import sticker1 from './Sticker1.svg';
import sticker2 from './Sticker2.svg';

export type ControllerProps = {
  isMobile: boolean;
  modalId?: string;
};

const Widget: FC<WidgetProps<ControllerProps>> = ({ isMobile, modalId = '' }) => {
  const settings = useSettings();

  const setScroll = (isOpen: boolean) => {
    if (isOpen && document && window) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    } else if (document && window) {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  };
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [hrefElement, setHrefElement] = React.useState<any>(null);
  useEffect(()=>{
    if (isOpenModal) {
      const closeButton = document.getElementById('close-button')
      closeButton && closeButton.focus()
    }
  }, [isOpenModal]);

  useEffect(()=>{
    const modalElement = document.getElementById('modal-link')
    setHrefElement(modalElement)
    if (document) {
      document.onkeyup = function(e) {
        if (e.code === 'Escape'){
          setScroll(false);
          setIsOpenModal(false);
          modalElement && modalElement.focus();
        }
      }
    }
  }, []);



  return settings.get(settingsParams.isCustom) ?
    <div
      data-hook="igulletovawidget-wrapper"
      style={{ borderRadius: '8px', width: '100%', height: '100%' }}
    >
      <>
        <div className={isOpenModal ? classes.openedPopup : classes.popup}>
          <div           className={classes.closeModal}>

          <button
            id={'close-button'}
            className={classes.closeModalButton}
            onClick={() => {
              setScroll(false);
              setIsOpenModal(false);
              hrefElement && hrefElement.focus();

            }}

          >
            <img alt="בחזרה לאתר" style={{ margin: '14px' }} src={closeButtoImg} />
          </button>
          </div>
          <iframe
            title={"עיגול לטובה"}
            style={{ width: '100%', height: '100%' }}
            name={`Iframe-${modalId}`}
          />
        </div>
        <div
          className={
            isMobile ? classes.widgetWrapperMobile : classes.widgetWrapper
          }
          data-hook={'widget-content-wrapper'}
        >
          <img
            className={isMobile ? classes.mainImageMobile : classes.mainImage}
            src={igulLetovaLogo}
            data-hook={'main-image'}
            alt={"עיגול לטובה כסף קטן משנה הרבה לוגו"}
          />
          <div className={classes.contentWrapper}>
            <div className={isMobile ? classes.headerMobile : classes.header}>
              <h2 data-hook="app-title">
                {settings.get(settingsParams.greetingsText)}
              </h2>
            </div>

            <button
              data-hook="button-link"
              className={
                isMobile ? classes.mainButtonMobile : classes.mainButton
              }
              tabIndex={-1}
              role={"presentation"}
            >
              <a
                data-hook="button-link-text"
                style={{ color: 'inherit', display:"block" }}
                onClick={() => {
                  setScroll(true);
                  setIsOpenModal(true);

                }}
                id={'modal-link'}
                href={
                  'https://www.igul.org.il/?gclid=EAIaIQobChMIstae_bvo6wIVyLTtCh3f9QAjEAAYAiAAEgJHgfD_BwE'
                }
                target={`Iframe-${modalId}`}
              >
                {settings.get(settingsParams.contentText)}
              </a>
            </button>
          </div>
        </div>
      </>
    </div>
  : (
    <div       data-hook="igulletovawidget-wrapper">
    <div
      data-hook={'widget-content-wrapper'}
      style={{ width: '100%', height: '100%' }}
    >
      <div className={isOpenModal ? classes.openedPopup : classes.popup}>
        <div           className={classes.closeModal}
        >
        <button
          id={'close-button'}
          className={classes.closeModalButton}
          onClick={() => {
            setScroll(false);
            setIsOpenModal(false);
            hrefElement && hrefElement.focus();
          }}


        >
          <img alt="בחזרה לאתר" style={{ margin: '14px' }} src={closeButtoImg} />
        </button>
        </div>
        <iframe title={"עיגול לטובה"} style={{ width: '100%', height: '100%' }} name={`Iframe-${modalId}`}/>
      </div>
      <a
        style={{display:"block"}}
        id={'modal-link'}
        data-hook="button-link-text"
        onClick={() => {
          setScroll(true);
          setIsOpenModal(true);
        }}
        href={
          'https://www.igul.org.il/?gclid=EAIaIQobChMIstae_bvo6wIVyLTtCh3f9QAjEAAYAiAAEgJHgfD_BwE'
        }
        target={`Iframe-${modalId}`}
      >
        <img
          alt={"להצטרפות מהירה"}
          data-hook={'sticker-image'}
          style={{ width: '100%', height: '100%' }}
          src={
            settings.get(settingsParams.stickerOption) === 1
              ? sticker1
              : sticker2
          }
        />
      </a>
    </div>
    </div>
  );
};
export default Widget;
