
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/dbd519ebd96be495/igul-letova-integration/src/components/igulletovawidget/Widget/index.tsx';
  import {
    HttpProvider,
  } from '@wix/yoshi-flow-editor';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor-runtime/build/esm/i18next/init';



  import stylesParams from '/home/builduser/agent00/work/dbd519ebd96be495/igul-letova-integration/src/components/igulletovawidget/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://8a61be5486d247c7a0eef42564383fd4@sentry.wixpress.com/673',
      id: '8a61be5486d247c7a0eef42564383fd4',
      projectName: 'igul-letova-integration',
      teamName: 'wix-developers',
    };

  var UserComponent = getWidgetWrapper({
      initI18n,
      HttpProvider,
      withStylesHoc: withStyles,
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'igulletovawidget',
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
