import {
  StyleParamType,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';

type IStylesParams = {
  backgroundColor: StyleParamType.Color;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
  buttonBackgroundColor: StyleParamType.Color;
  buttonTextColor: StyleParamType.Color;
  buttonFont: StyleParamType.Font;
  buttonBorderWidth: StyleParamType.Number;
  buttonCorner: StyleParamType.Number;
  widgetBorderWidth: StyleParamType.Number;
  widgetCorner: StyleParamType.Number;
  widgetBorderColor: StyleParamType.Color;
  buttonBorderColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    key: 'backgroundColor',
    getDefaultValue: () => ({ value: 'white', opacity: 1, name: '' }),
  },
  textFont: {
    type: StyleParamType.Font,
    key: 'textFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 28,
      cssFontFamily: 'alef-regular,sans-serif',
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    key: 'textColor',
    getDefaultValue: () => ({ value: '#4c9d24', opacity: 1, name: '' }),
  },
  buttonBackgroundColor: {
    type: StyleParamType.Color,
    key: 'buttonBackgroundColor',
    getDefaultValue: () => ({ value: '#4c9d24', opacity: 1, name: '' }),
  },
  buttonTextColor: {
    type: StyleParamType.Color,
    key: 'buttonTextColor',
    getDefaultValue: () => ({ value: 'white', opacity: 1, name: '' }),
  },
  buttonFont: {
    type: StyleParamType.Font,
    key: 'buttonFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 18,
      cssFontFamily: 'alef-regular,sans-serif',
    }),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    key: 'buttonBorderWidth',
    getDefaultValue: () => 0,
  },
  buttonCorner: {
    type: StyleParamType.Number,
    key: 'buttonCorner',
    getDefaultValue: () => 0,
  },
  widgetBorderWidth: {
    type: StyleParamType.Number,
    key: 'widgetBorderWidth',
    getDefaultValue: () => 0,
  },
  widgetCorner: {
    type: StyleParamType.Number,
    key: 'widgetCorner',
    getDefaultValue: () => 0,
  },
  widgetBorderColor: {
    type: StyleParamType.Color,
    key: 'widgetBorderColor',
    getDefaultValue: () => ({ value: '#8ce22b', opacity: 1, name: '' }),
  },
  buttonBorderColor: {
    type: StyleParamType.Color,
    key: 'buttonBorderColor',
    getDefaultValue: () => ({ value: '#8ce22b', opacity: 1, name: '' }),
  },
});
