import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';

export type ISettingsParams = {
  greetingsText: SettingsParamType.Text;
  contentText: SettingsParamType.Text;
  isCustom: SettingsParamType.Boolean;
  stickerOption: SettingsParamType.Number;
};

export default createSettingsParams<ISettingsParams>({
  greetingsText: {
    getDefaultValue: ({ t }) =>
      `${t('app.settings.defaults.content.title.first.option')}`,
  },
  contentText: {
    getDefaultValue: ({ t }) =>
      `${t('app.settings.defaults.content.content.first.option')}`,
  },
  isCustom: {
    getDefaultValue: () => false
  },
  stickerOption: {
    getDefaultValue: () => 1
  }
});
